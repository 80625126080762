import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AccountDetails } from '@aaa/emember/store-types';
import { MembershipAutoRenewForm, MembershipAutoRenewFormStep } from './membership-auto-renew.models';
import { changedForm, setFormActiveStep, validateFormStep } from '../../modules/share/ngrx-helpers';
import { RequestError } from '../generic-errors';

export const MembershipARActions = createActionGroup({
  source: 'Membership Auto Renew',
  events: {
    ...setFormActiveStep<MembershipAutoRenewFormStep>(),
    ...validateFormStep<MembershipAutoRenewFormStep>(),
    ...changedForm<MembershipAutoRenewForm>(),

    'Remove Card': emptyProps(),
    'Remove Card Succeeded': props<{ accountDetails?: AccountDetails }>(),
    'Remove Card Failed': props<{ error: RequestError }>(),
    'Update Card': emptyProps(),
    'Update Card Succeeded': props<{ accountDetails?: AccountDetails }>(),
    'Update Card Failed': props<{ error: RequestError }>(),
    'Add Card': emptyProps(),
    'Add Card Succeeded': props<{ accountDetails?: AccountDetails }>(),
    'Add Card Failed': props<{ error: RequestError }>(),
    Reset: emptyProps(),
    'Remove Error': emptyProps(),
  },
});
