import { RequestStatus } from '../../../types/request-status';
import { FormControl, FormGroup } from '@angular/forms';
import { BillingForm } from '../../modules/share/billing-form';
import { FormGroupValue } from '../../modules/share/form.utils';
import { RequestError } from '../generic-errors';

export enum MembershipAutoRenewFormStep {
  UPDATE_CARD = 'updateCard',
  ADD_CARD = 'addCard',
}

export interface MembershipAutoRenewForm {
  billing: FormGroup<BillingForm>;
  promoCode: FormControl<string>;
}

export interface MembershipAutoRenewState {
  formActiveStep: MembershipAutoRenewFormStep;
  formValues: FormGroupValue<MembershipAutoRenewForm>;

  removeCardRequestStatus: RequestStatus;
  removeCardError: RequestError | null;
  addCardRequestStatus: RequestStatus;
  addCardError: RequestError | null;
  updateCardRequestStatus: RequestStatus;
  updateCardError: RequestError | null;
}
